import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import DataTable from '../tables/DataTable';
import withTable from '../tables/withTable';
import { 
  loadBuyerPurchased, resetBuyerArbitration, resetBuyerPurchased, 
} from '../../actions';
import { getConfig, compose } from '../../utils/helpers';
import styles from './styles';
import { toastr } from 'react-redux-toastr';
import TransportationModal from '../transportation/TransportationModal';
import { ArbitrationModal } from '../arbitration';

// Until mp feature is created, only show arb button for these mpid's
const ARBITRATION_ENABLED_MPIDS = ['564', '587', '591', '592', '594' ];

class BuyerPurchased extends Component {
  
  constructor(props) {
    super(props);
    this.loadData();
    
    this.state = {
      title: 'purchased',
      isTransportationModalOpen: false,
      isArbitrationModalOpen: false,
      itemToBeShipped: {
        itemId: '',
        vehicleZip: '',
        vin: '',
      },
      itemSelectedForArbitration: {
        arbitrationStatus: '', 
        enableRequestArbitration: 0, 
        enableAddArbitrationComment: 0, 
        enableCancelArbitration: 0,
        itemId: '', 
        rowIndex: null,
      }
    }
  }

  /*  
    componentDidMount() {
    this.loadData();
  } */

  loadData = () => {
    this.props.resetBuyerPurchased();
    this.props.loadBuyerPurchased(getConfig('marketplaceId'));
  };

  handleArbitrationRefresh = () => {
    const {
      arbitrationStatus, 
      enableRequestArbitration, 
      enableAddArbitrationComment, 
      enableCancelArbitration,
      rowIndex,
      itemId
    } = this.props.buyerPurchased.data[this.state.itemSelectedForArbitration.rowIndex];
    this.setState({
      itemSelectedForArbitration: {
        arbitrationStatus, 
        enableRequestArbitration, 
        enableAddArbitrationComment, 
        enableCancelArbitration,
        rowIndex,
        itemId
      }
    })
  }

  handleCompleteTransportation = checkoutUrl => {
    window.open(checkoutUrl, '_blank');
  };

  handleDetailsClick = (itemId, data) => {
    const path = `/item/${itemId}`;
    this.props.navigate(path);
    const ids = (data || [])
      .map(item => item.itemId || item.id || item._id)
      .join(',');
    sessionStorage.setItem('navItems', ids);
  };

  onArbitrationClick = (e) => {
    e.preventDefault();
    const rowIndex = Number(e.target.dataset.vehicleIndex);
    const rowData = this.props.buyerPurchased.data[rowIndex];
    const {
      arbitrationStatus, 
      enableRequestArbitration, 
      enableAddArbitrationComment, 
      enableCancelArbitration,
      itemId, 
    } = rowData;
    this.setState({
      itemSelectedForArbitration: {
        arbitrationStatus, 
        enableRequestArbitration, 
        enableAddArbitrationComment, 
        enableCancelArbitration,
        itemId, 
        rowIndex,
      },
      isArbitrationModalOpen: true,
    })
    
  }

  onShippingClick = (e) => {
    e.preventDefault();
    const rowIndex = Number(e.target.dataset.vehicleIndex);
    const rowData = this.props.buyerPurchased.data[rowIndex];
    const {itemId, vehicleYear, make, model, amount, status, vin, vehicleZip, shippingStatusId } = rowData;
    const rowDataParsed = {itemId, vehicleYear, make, model, amount, status, vin, vehicleZip, shippingStatusId }
        
    this.handleShippingClick(rowDataParsed);
  }

  handleCloseArbitrationModal = () => {
    this.props.loadBuyerPurchased(getConfig('marketplaceId'))
    this.setState({isArbitrationModalOpen: false});
  }

  handleShippingClick = async (rowData) => {
    const vehicleZip = [null,undefined].includes(rowData.vehicleZip)
      ? ''
      : rowData.vehicleZip;
    const {zipcode} = this.props.userProfile?.user;
    if (!vehicleZip) {
      toastr.error(
        'Shipping quote error',
        'Origin ZIP code not found',
        {position: 'bottom-center'}
      );
    } else {
      this.setState({
        itemToBeShipped: {...rowData, vehicleZip, shippingDetails: { shippingStatusId: rowData.shippingStatusId} },
        isTransportationModalOpen: true
      });
    }
  }

  renderArbitrationModal = ({
    arbitrationStatus,
    itemId, 
    enableAddArbitrationComment, 
    enableCancelArbitration, 
    enableRequestArbitration
  }) => {
    return (
      <ArbitrationModal
        arbType={'buyer'}
        arbitrationStatus={arbitrationStatus}
        enableRequestArbitration={enableRequestArbitration}
        enableAddArbitrationComment={enableAddArbitrationComment}
        enableCancelArbitration={enableCancelArbitration}
        isOpen={this.state.isArbitrationModalOpen}
        itemId={itemId}
        onAgreedToTerms={this.handleArbitrationRefresh}
        onClose={this.handleCloseArbitrationModal}
        onOpen={()=>null}
      />
    )
  }

  renderShippingModal = (itemToBeShipped) => {
    const {isTransportationModalOpen, isArbitrationModalOpen} = this.state;
    const {user} = this.props.userProfile;
    return (
      <TransportationModal
          itemData={itemToBeShipped}
          mpId={getConfig('marketplaceId')}
          user={user}
          isOpen={isTransportationModalOpen}
          onClose={() => this.setState({ isTransportationModalOpen: false })}
      />
    )
  }

  render() {
    const loading = isEmpty(this.props.buyerPurchased);
    const data = this.props.buyerPurchased.data || [];
    let rowNumber = -1;
    const columns = this.props.makeColumns([
      'checkbox',
      'image',
      'lotNumber',
      'vin',
      'year',
      'make',
      'model',
      'amount',
      'buyFee',
      'seller',
      'buyer',
      'purchaseDate',
      'paymentStatus',
      // 'arbitrationStatus', // 2024.10.07 - disabling column temporarily
      'titleStatus',
      {
        Header: 'Actions',
        id: 'buttons',
        headerStyle: { background: 'none' },
        sortable: false,
        filterable: false,
        Cell: row => {
          const {enableRequestArbitration, enableCancelArbitration, enableAddArbitrationComment} = row.original;
          const features = (this.props.marketplaceFeatures.features || '').split(',');
          const showArbitrationButton = 
            ARBITRATION_ENABLED_MPIDS.includes(getConfig('marketplaceId'))
            && Boolean(enableRequestArbitration || enableCancelArbitration || enableAddArbitrationComment);
          const showShippingButton = features.includes('600') || features.includes('601');
          const showCompletePurchaseButton = !!row.original.checkoutUrl;
          const isPaymentInProgress = row.original.paymentStatusId === 2 || row.original.paymentStatus === 'In Progress';
          const isPaymentAccepted = row.original.paymentStatusId === 3 || row.original.paymentStatus === 'Accepted';
          const showGatepassButton = 
            /* Payment Status is: 'Accepted'|'In Progress'  AND  Transaction Status is 'In Progress' or Complete */
            (isPaymentAccepted || isPaymentInProgress) && 
            ([2,4].includes(row.original.transactionStatusId))
          rowNumber++;
          const isShippingRequested = false;
          return (
            // TODO:  Make height more dynamic based on # of buttons to render
            <div style={{...styles.buttonGroup, height: 150}}>
              {/* "View Details" */}
              <Button
                style={styles.button}
                onClick={() =>
                  this.handleDetailsClick(row.original.itemId, data)
                }
              >
                View Details
              </Button>

              {/* buyerReceipt - "Bill of Sale" */}
              {row.original.buyerReceipt && (
                <a
                  style={styles.button}
                  href={getConfig('apiRoot') + row.original.buyerReceipt}
                >
                  Bill of Sale
                </a>
              )}

              {/* gatePass - "Gatepass" link */}
              {(row.original.gatePass && showGatepassButton) && (
                <a
                  style={styles.button}
                  href={getConfig('apiRoot') + row.original.gatePass}
                >
                  Gatepass 
                </a>
              )}

              {/* "Shipping" */}
              {showShippingButton && 
                (!isShippingRequested ? 
                  <Button 
                    style={styles.button}
                    onClick={this.onShippingClick}
                    data-vehicle-index={rowNumber}
                    title="Request Transport Service"
                  > Shipping ... </Button> :

                  <Button
                    style={{
                      ...styles.button, 
                      backgroundColor: 'lightGray', 
                      cursor: 'default', 
                      color: 'darkSlateGrey'
                    }}
                    data-vehicle-index={rowNumber}
                    title="Transport has been requested"
                  > {"Requested \u{2714}"}</Button>)
              }

              {/* Receipt Report */}
              {row.original.buyerReceiptReport && (
                <a
                  style={styles.button}
                  href={getConfig('apiRoot') + row.original.buyerReceiptReport}
                >
                  Receipt Report
                </a>
              )}
              {/* Arbitration */}
              {showArbitrationButton && (
                <Button 
                  style={{
                    ...styles.button, 
                    backgroundColor: enableAddArbitrationComment ? '#ff9521' : styles.button.backgroundColor,
                  }} 
                  onClick={this.onArbitrationClick}
                  data-vehicle-index={rowNumber}
                  title={"Edit, view, or new Arbitration"}
                >
                  Arbitration
                </Button>
              )}
              {/* TODO - 4.16.2024 -- Remove; Transport services not handled directly in MP */}
              {showCompletePurchaseButton && (
                <div style={{ width: '100%' }}>
                  <Button
                    style={{
                      ...styles.button,
                      margin: 0,
                      padding: 4,
                      height: 'auto',
                    }}
                    onClick={e =>
                      this.handleCompleteTransportation(
                        row.original.checkoutUrl
                      )
                    }
                  >
                    Complete Transportation
                  </Button>
                </div>
              )}
            </div>
          );
        },
      },
    ]);

    return (
      <div className="st-container shadow padding background-white padding">
        {this.renderShippingModal(this.state.itemToBeShipped)}
        {this.renderArbitrationModal({...this.state.itemSelectedForArbitration})}
        <h4>Purchased Vehicles</h4>
        <DataTable
          loading={loading}
          title={this.state.title}
          data={data}
          columns={columns}
          showCSVButton={true}
          showPrintButton={true}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { 
    buyerPurchased,
    marketplaceFeatures, 
    userProfile 
  } = state.entities;
  return { 
    buyerPurchased, 
    marketplaceFeatures,
    userProfile
  };
};

export default compose(
  withTable,
  connect(mapStateToProps, { 
    loadBuyerPurchased, resetBuyerPurchased, resetBuyerArbitration
  })
)(BuyerPurchased);
