import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBuyerArbitrationComments, loadBuyerArbitrationAddComment, loadBuyerArbitrationCancel, loadBuyerArbitrationComments, loadBuyerArbitrationNewRequest, loadBuyerPurchased, loadSellerArbitrationAddComment, loadSellerArbitrationComments } from '../../actions';
import { getConfig } from '../../utils/helpers';
import { toastr } from 'react-redux-toastr';
import { arbitrationModalConstants } from './arbitrationModalConstants';
import { ArbitrationModalTerms } from './ArbitrationModalTerms';
import { ArbitrationModalMain } from './ArbitrationModalMain';
import { ArbitrationModalHeader } from './ArbitrationModalHeader';
import { ArbitrationModalFooter } from './ArbitrationModalFooter';

/** Modal containing actionables and history for a Buyer and Seller Arbitration request.
 * - _Note:  Arbitration can only be initially requested by a Buyer_
 * 
 * @param {{
 *  arbitrationStatus: string,
 *  arbType: 'buyer'|'seller',
 *  enableAddArbitrationComment?: 0 | 1,
 *  enableCancelArbitration?: 0 | 1,
 *  enableRequestArbitration?: 0 | 1, 
 *  itemId: string, 
 *  onClose: ()=>void, 
 *  onAgreedToTerms: ()=>void,
 *  onOpen: ()=>void, 
 *  isOpen: boolean}} props
 * 
 * @returns {React.ReactNode}
 */
export function ArbitrationModal ({itemId, arbitrationStatus,enableAddArbitrationComment, enableCancelArbitration, enableRequestArbitration, onClose, onOpen, onAgreedToTerms, isOpen, arbType}) {
    /* Redux dispatch */
    const dispatch = useDispatch();

    /* State */
    const [isLoading, setIsLoading] = useState(false);
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [arbFormId, setArbFormId] = useState(arbType === 'seller' ? '3' : (enableRequestArbitration === 1) ? '1' : '2');
    const [isSubmissionDisabled, setIsSubmissionDisabled] = useState(true);
    const [commentTextInput, setCommentTextInput] = useState("");

    /* Refs */
    const commentInputRef = useRef("");
    const commentAttachmentRef = useRef("");
    const inputFileElementRef = useRef("");

    /* Effects */
    useEffect(()=>{setArbFormId(arbType === 'seller' ? '3' : (enableRequestArbitration === 1) ? '1' : '2')},[enableRequestArbitration, arbType])
    useEffect(() => {
        if (!itemId) {
            return;
        } else if (arbType === 'buyer') {
            dispatch(loadBuyerArbitrationComments(itemId));
        } else if (arbType === 'seller') {
            dispatch(loadSellerArbitrationComments(itemId));
        }
    }, [itemId, dispatch, arbType])
    const {customerTermsLink, arbitration} = useSelector(reduxState => {        
        const {customerTermsLink, arbitration} = reduxState.entities;
        return {customerTermsLink, arbitration }
    });
    
    /* Callbacks */
    const resetFormValues = () => {
        commentInputRef.current = "";
        commentAttachmentRef.current = "";
        if (inputFileElementRef.current) {
            inputFileElementRef.current.value = "";
        }
        setCommentTextInput("");
    }
    const handleAddAttachment = (e) => {
        const files = e.target.files;
        if (files) {
            const file = files[0];
            commentAttachmentRef.current = file;
            inputFileElementRef.current = e.target;
        }
    }
    const handleBuyerAddComment = (e) => {
        e.preventDefault();
    }
    const handleClose = (e) => {
        resetFormValues();
        setAgreedToTerms(false);
        onClose(e);
    }
    const handleCancelArbitration = (e) => {
        const cancelArbitration = (id) => {
            setIsLoading(true)
            dispatch(loadBuyerArbitrationCancel(id))
            .then(res => {
                setIsLoading(false);
                onClose();
            })
            .catch(resError => {
                console.error(resError?.message || resError);
                setIsLoading(false);

            })
        }
        toastr.confirm(
            "Are you sure you want to cancel the request?",
            {
                timeOut: 20000,
                cancelText: "Close",
                okText: "Cancel Arbitration",
                onOk: ()=>cancelArbitration(itemId),
                onCancel: ()=>null,
            }
        )
    }
    const handleCommentChange = (e) => {
        commentInputRef.current = e.target.value;
        if (!!e.target.value === isSubmissionDisabled) {
            setIsSubmissionDisabled(prev => !prev)
        }
        setCommentTextInput(e.target.value)
    }
    const handleSellerAddComment = (e) => {
        e.preventDefault();
        
    }
    const handleSubmit = (e, itemId) => {
        e.preventDefault();
        const submissionType = e.target.dataset.arbFormId;
        const {formId} = arbitrationModalConstants;
        switch (submissionType) {
            /* Buyer -> Agreed to Terms */
            case formId.TERMS:
                setIsLoading(true);
                dispatch(loadBuyerArbitrationNewRequest(itemId))
                .then(res => {
                    return dispatch(loadBuyerArbitrationComments(itemId))
                })
                .then(res => {
                    return dispatch(loadBuyerPurchased(getConfig('marketplaceId')))
                })
                .then(res => {
                    onAgreedToTerms();
                    setArbFormId(formId.BUYER);
                    setIsSubmissionDisabled(true);
                    
                    setIsLoading(false);
                })
                .catch(newArbErrResponse=>{
                    setIsLoading(false);
                    toastr.error('Error with ArbitrationRequest', JSON.stringify(newArbErrResponse, null, '\t'))
                })
                break;
            /* Buyer -> Add a comment */
            case formId.BUYER:
                setIsLoading(true);
                dispatch(loadBuyerArbitrationAddComment(itemId, commentInputRef.current, commentAttachmentRef.current))
                .then(res => {
                    return dispatch(fetchBuyerArbitrationComments({itemId}))
                })
                .then(res => {
                    resetFormValues();
                    setIsSubmissionDisabled(true);
                    setIsLoading(false);
                })
                .catch(addCommentErr => {
                    console.error(JSON.stringify(addCommentErr?.message | addCommentErr));
                    renderToastrError('Error adding comment', addCommentErr?.message || '');
                    setIsLoading(false);
                })
                break;
            /* Seller -> Add a comment */
            case formId.SELLER:
                setIsLoading(true);
                dispatch(loadSellerArbitrationAddComment(itemId, commentInputRef.current, commentAttachmentRef.current))
                .then(res => {
                    return dispatch(loadSellerArbitrationComments(itemId))
                })
                .then(res => {
                    resetFormValues();
                    setIsSubmissionDisabled(true);
                    setIsLoading(false);
                })
                .catch(addCommentErr => {
                    console.error(JSON.stringify(addCommentErr?.message | addCommentErr));
                    renderToastrError('Error adding comment', addCommentErr?.message || '');
                    setIsLoading(false);
                })
                break;
            default:
                break;
        }
    }
    const handleAgreedToggle = (e) => {
        setAgreedToTerms(prevState => !prevState);
        if (isSubmissionDisabled){
            setIsSubmissionDisabled(false)
        }
    }

    /* Render functions */
    const renderToastrError = (title='An Error has occurred', message='') => {
        toastr.error(
            title, 
            message, 
            {
                icon: 'warning',
            }
        )
    }

    return (
        <Modal
            isOpen={isOpen}
            className={'marketplace-reusable__modal-content'}
            overlayClassName={'marketplace-reusable__modal-overlay'}
            
        >
            {isLoading ? <label className='marketplace__modal-loading-text'>Loading ...</label> : null}
            <div 
                id='mp-arb-modal'
                className={`marketplace-reusable__modal-container${isLoading ? ' modal-container-loading' : ''}`} 
            >
                <ArbitrationModalHeader 
                    handleClose={onClose} 
                    arbitrationStatus={arbitrationStatus} 
                />
                <hr className='marketplace-reusable__modal-divider' />
                {{
                    [arbitrationModalConstants.formId.TERMS]: 
                        <ArbitrationModalTerms 
                            onAgreedToggle={handleAgreedToggle} 
                            termsUrl={customerTermsLink} 
                        />,
                    [arbitrationModalConstants.formId.BUYER]:
                        <ArbitrationModalMain 
                            arbType={"buyer"}
                            comments={arbitration.buyerComments || []}
                            onAddComment={handleBuyerAddComment}
                            onFileSelect={handleAddAttachment}
                            onCommentChange={handleCommentChange}
                            inputTextValue={commentTextInput}
                        />,
                    [arbitrationModalConstants.formId.SELLER]:
                        <ArbitrationModalMain 
                            arbType={"seller"}
                            comments={arbitration.sellerComments || []}
                            onAddComment={handleSellerAddComment} 
                            onFileSelect={handleAddAttachment}
                            onCommentChange={handleCommentChange}
                            inputTextValue={commentTextInput}
                        /> 
                }[arbFormId]}

                <hr className='marketplace-reusable__modal-divider' />
                
                <ArbitrationModalFooter
                    cancelButtonLabel={{'1': 'Cancel', '2': 'Close', '3': 'Close'}[arbFormId]}
                    enableAddArbitrationComment={enableAddArbitrationComment}
                    isSubmitDisabled={(!agreedToTerms && arbFormId === '1') || isSubmissionDisabled} 
                    onCancel={handleClose}
                    onCancelArbitration={handleCancelArbitration}
                    onSubmit={(e)=>handleSubmit(e, itemId)}
                    showCancelArbitrationButton={enableCancelArbitration}
                    submitButtonLabel={{'1': 'Request Arbitration', '2': 'Add Comment', '3': 'Add Comment'}[arbFormId]}
                    submitFormId={arbFormId}
                />

            </div>

        </Modal>
    )
}

export default ArbitrationModal;