const prodConfigs = require('./index.prod');
const {
  makeApiRoot,
  makeIASConnectRoot,
  makeCoreRoot,
  makeSearchRoot,
  makeKeycloakUrl,
  makeSimulcastUrl,
  makeBookSheetUrl,
  makeSIAConnectRoot,
  makeAnylineLicense,
  makeHyperlaneUrl,
} = require('./configHelpers');

const baseConfig = {
  runbuggyConfig: {
    scriptSrc: 'https://ng-staging.runbuggy.com/staging/web-components/staging/runbuggy.min.js',
    clientId: 'ddf553b3-acb7-4d87-83db-388faa3a0a8f'
  }
}

const config = {
  'dealernet-qa.iasmarketplace.com': {
    ...prodConfigs['dealernet.iasmarketplace.com'],
    ...baseConfig,
    apiRoot: makeApiRoot('awg-qa'),
    searchRoot: makeSearchRoot('ias-us-dealernet', '-qa'),
    iasConnectRoot: makeIASConnectRoot('signin-qa-dealernet'),
    booksheetUrl: makeBookSheetUrl('dealernet-mp'),
    liveSimulcastUrl: makeSimulcastUrl('dealernet-mp'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace-QA', '-qa'),
    coreRoot: makeCoreRoot('awg-qa'),
    anylineLicenseKey: makeAnylineLicense('dealernet-qa.iasmarketplace.com'),
    gaId: null,
  },
  'equipment-qa.holman.com': {
    ...prodConfigs['equipment.holman.com'],
    ...baseConfig,
    // iasConnectRoot: 'https://signin-equipment-qa.holman.com', // custom
    iasConnectRoot: makeIASConnectRoot('signin-qa-holman'),
    apiRoot: makeApiRoot('equipment-qa'),
    searchRoot: makeSearchRoot('ias-us-holman', '-qa'),
    liveSimulcastUrl: makeSimulcastUrl('equipment-qa-mp'),
    booksheetUrl: makeBookSheetUrl('equipment-qa-mp'),
    keycloakUrl: makeKeycloakUrl('ARI-Fleet-MP-QA', '-qa'),
    coreRoot: makeCoreRoot('awg-qa'),
    realTimeUrl:
      'https://equipment-qa.autoremarketers.com/web/realtime/notificationHub',
    gaId: null,
  },
  'ice-qa.iasmarketplace.com': {
    ...prodConfigs['ice.iasmarketplace.com'],
    ...baseConfig,
    iasConnectRoot: makeIASConnectRoot('signin-qa-ice'),
    apiRoot: makeApiRoot('awg-qa'),
    searchRoot: makeSearchRoot('ias-us-ice', '-qa'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace-QA', '-qa'),
    coreRoot: makeCoreRoot('awg-qa'),
    // liveSimulcastUrl: makeSimulcastUrl('awg-qa'),
    // hyperlaneUrl: makeHyperlaneUrl('awg-qa'),
    anylineLicenseKey: makeAnylineLicense('ice-qa.iasmarketplace.com'),
    gaId: null,
  },
  'lorensen-qa.siamarketplace.com': {
    ...prodConfigs['lorensen.siamarketplace.com'],
    ...baseConfig,
    iasConnectRoot: makeIASConnectRoot('signin-qa-lorensen'),
    apiRoot: makeApiRoot('awg-qa'),
    searchRoot: makeSearchRoot('ias-us-lorensen', '-qa'),
    liveSimulcastUrl: makeSimulcastUrl('lorensen-qa'),
    hyperlaneUrl: makeHyperlaneUrl('lorensen-qa'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace-QA', '-qa'),
    coreRoot: makeCoreRoot('awg-qa'),
    anylineLicenseKey: makeAnylineLicense('lorensen-qa.siamarketplace.com'),
    gaId: null,
    marketplaceId: '564',
    enableBuyerTitles: false,
    enableSellerTitles: false,
  },

  default: {
    marketplaceId: '0',
  },
};

module.exports = config;
