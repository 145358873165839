import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { isEmpty } from 'lodash';
import HyperLotButton from './HyperLotButton';
import PrivateLotButton from './PrivateLotButton';
import UpdatePriceModal from '../UpdatePriceModal';
import { Button } from 'semantic-ui-react';
import DataTable from '../tables/DataTable';
import withTable from '../tables/withTable';
import { confirm } from '../common/confirm/CallConfirm';
import CoreButton from '../core/components/ui/buttons/CoreButton';
import {
  loadSellerInventory,
  resetSellerInventory,
  loadUnlistItem,
} from '../../actions';
import { getConfig, compose } from '../../utils/helpers';
import styles from './styles';
import $ from 'jquery';
import 'bootstrap';

class SellerListed extends Component {
  state = {
    title: 'seller listed',
    item: null,
    coreEnabled: undefined,
    isUpdatePriceModalOpen: false,
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    const { features } = this.props.marketplaceFeatures;
    if (features && this.state.coreEnabled === undefined) {
      const coreEnabled = features.includes('555');
      this.setState({ coreEnabled });
    }
  }

  loadData = () => {
    this.props.resetSellerInventory();
    this.props.loadSellerInventory(getConfig('marketplaceId'));
  };

  handleUpdateClick = item => {
    this.setState({ item, isUpdatePriceModalOpen: true });
  };

  handleDetailsClick = (itemId, data) => {
    const path = `/item/${itemId}`;
    this.props.navigate(path);
    const ids = (data || [])
      .map(item => item.itemId || item.id || item._id)
      .join(',');
    sessionStorage.setItem('navItems', ids);
  };

  handleUnlistClick = row => {
    confirm('Are you sure you want to unlist this vehicle?').then(
      () => {
        this.props
          .loadUnlistItem(getConfig('marketplaceId'), row.itemId)
          .then(({ response }) => {
            if (!response || response.wsStatus === 'Error') {
              toastr.error('Error', response.wsMessage);
            } else if (response.wsStatus === 'Success') {
              toastr.success('Success', response.wsMessage);
              this.loadData();
            }
          });
      },
      () => null
    );
  };

  render() {
    const loading = isEmpty(this.props.sellerInventory);
    const data = (this.props.sellerInventory.data || []).filter(
      item => item.listingStatusId === 1
    );
    const columns = this.props.makeColumns([
      'checkbox',
      'image',
      'vin',
      'year',
      'make',
      'model',
      'mileage',
      'seller',
      'reservePrice',
      'buyNow',
      'newOrUsed',
      'retailOrWholesale',
      'daysInMarketplace',
      'timesToRerun',
      'event',
      this.state.coreEnabled ? 'locked' : null,
      {
        Header: 'Actions',
        headerStyle: { background: 'none' },
        id: 'buttons',
        sortable: false,
        filterable: false,
        Cell: row => (
          <div style={styles.buttonGroup}>
            <Button
              style={styles.button}
              onClick={() => this.handleDetailsClick(row.original.itemId, data)}
            >
              View Details
            </Button>
            {this.state.coreEnabled ? (
              <CoreButton
                sellerActivityList="Listed"
                label="Edit"
                existingVin={row.original.vin}
              />
            ) : (
              <Button
                style={styles.button}
                onClick={() => this.handleUpdateClick(row.original)}
              >
                Update
              </Button>
            )}
            <Button
              style={styles.button}
              onClick={() => this.handleUnlistClick(row.original)}
            >
              Unlist
            </Button>
          </div>
        ),
      },
    ]);

    return (
      <div className="st-container shadow padding background-white padding">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h4>Listed Vehicles</h4>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {this.state.coreEnabled && (
              <CoreButton
                style={{ minHeight: 32 }}
                sellerActivityList="Listed"
                label="Add New +"
              />
            )}
            {!loading && data.length > 0 && (
              <PrivateLotButton items={this.props.selectedData} />
            )}
            {!loading && data.length > 0 && (
              <HyperLotButton items={this.props.selectedData} />
            )}
          </div>
        </div>

        <div>
          <UpdatePriceModal
            isOpen={this.state.isUpdatePriceModalOpen}
            onClose={() =>
              this.setState({ isUpdatePriceModalOpen: false, item: null })
            }
            item={this.state.item}
            loadData={this.loadData}
            navigate={this.props.navigate}
          />
          <DataTable
            loading={loading}
            title={this.state.title}
            data={data}
            columns={columns}
            showCSVButton={true}
            showPrintButton={true}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { sellerInventory, marketplaceFeatures } = state.entities;
  return { sellerInventory, marketplaceFeatures };
};

export default compose(
  withTable,
  connect(mapStateToProps, {
    loadSellerInventory,
    resetSellerInventory,
    loadUnlistItem,
  })
)(SellerListed);
